<template>
  <div class="backStage">
    <qlHeader theme="white" />

    <div class="backStage-wrapper flex bg-A9">
      <div class="left w-[240px]">
        <Sidebar rootPath="/data-prepare" :withRoot="true" :withQuery="true" />
      </div>

      <div class="backStage-container right flex-1 bg-A10">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import qlHeader from '@/components/header';
import Sidebar from '@components/sidebar/index.vue';

export default {
  name: 'data-prepare',

  components: {
    qlHeader,
    Sidebar,
  },

  methods: {
    // handle 返回首页
    handleBack() {
      this.$router.push('/home');
    },
  },

  mounted() {
    // 检查导入状态
    this.$batchEntry.checkTask();
  },
};
</script>

<style lang="scss" scoped>
.backStage {
  &-wrapper {
    height: calc(100vh - 64px);
  }

  &-container {
    @apply bg-A10;
    flex: 1;
    margin: 16px 16px 0;
    padding: 4px 24px 24px;
    overflow-x: auto;
    border-radius: 8px 8px 0 0;
  }
}
</style>
